@import url("https://fonts.googleapis.com/css?family=Pirata+One");

@font-face {
  font-family: "Campton-Book";
  src: url("../fonts/2C2FD2_1_0.eot");
  src: url("../fonts/2C2FD2_1_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/2C2FD2_1_0.woff") format("woff"),
    url("../fonts/2C2FD2_1_0.ttf") format("truetype");
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  overflow: hidden;
}

h1,
h2,
h3,
h4 {
  font-family: "Pirata One", cursive;
  font-size: 2.4rem;
  line-height: 2.4rem;
}

p {
  font-family: "Campton-Book", sans-serif;
  font-size: 1.6rem;
  line-height: 1.8rem;
}
